




















































































import { sleep } from '@/libs/util';
import MenuMixin from '@/mixins/menu-mixin';
import RoleServices, { RoleDto } from '@/services/role';
import { mixins } from 'vue-class-component';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import AddUser from './add-user.vue';
@Component({
  components: { AddUser }
})
export default class DictDetail extends mixins(MenuMixin) {
  roleSrv = new RoleServices();
  id: any = 0;
  info: RoleDto = {};

  created() {
    this.id = this.$route.query.id;
  }

  userList: any[] = [];
  async getRoleUserList() {
    this.userList = await this.roleSrv.getRoleUserList({ roleCode_eq: this.info.roleCode, ...this.filter });
  }

  async removeUser(record: RoleDto) {
    this.handleRemveUser([record.id || 0]);
  }

  filter: any = {};
  setListFilter(params?: object) {
    this.filter = params;
    this.getRoleUserList();
  }

  handleRemveUser(ids: number[]) {
    this.$confirm({
      title: '删除确认',
      content: '确定要删除选中用户吗？',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        let res = await this.roleSrv.removeRoleUser(ids);
        res && this.getRoleUserList();
      }
    });
  }

  selectedRows: any[] = [];
  onSelectChange(keys: any, rows: any) {
    this.selectedRows = keys;
  }

  handleBatch(e: any) {
    if (e.key === 'removeAll') {
      if (this.selectedRows.length === 0) {
        this.$message.error('至少选择一条数据');
        return false;
      }
      this.handleRemveUser(this.selectedRows);
    }
  }

  @Watch('$route')
  onRouteChange() {
    this.id = this.$route.query.id;
    this.onIdChange();
  }

  @State RELOAD_ROLE_DETAIL: any;

  timer: boolean = false;
  @Watch('id')
  @Watch('RELOAD_ROLE_DETAIL')
  async onIdChange() {
    if (!this.id || this.$route.path != '/platform/permision/detail') return false;
    if (this.timer) {
      await sleep(50);
      return false;
    }
    this.timer = true;
    this.info = await this.roleSrv.getInfo(this.id);
    this.getRoleUserList();
    this.timer = false;
  }

  visible: boolean = false;

  filterItems = [
    { label: '手机号', key: 'contractNumber_like', type: 'input' },
    { label: '用户姓名', key: 'userName_like', type: 'input' },
    { label: '生产域账号', key: 'domainAccount_like', type: 'input' }
  ];
}
