


















import DictMixin from '@/mixins/dict-mixin';
import PageMixin from '@/mixins/page-mixin';
import RoleServices from '@/services/role';
import { mixins } from 'vue-class-component';
import { Component, Prop, PropSync, Watch } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class AddUser extends mixins(DictMixin, PageMixin) {
  @PropSync('visible', { type: Boolean }) syncVisible!: boolean;
  @Prop() roleCode!: string;
  @Prop() info!: any;

  roleSrv = new RoleServices();

  filterItems = [{ label: '用户姓名 / 生产域账号', key: 'userName', type: 'input' }];

  columns = [
    { title: '姓名', dataIndex: 'userName' },
    { title: '部门', dataIndex: 'deptName' },
    { title: '员工号', dataIndex: 'workId' },
    { title: '生产域账号', dataIndex: 'loginName' },
    { title: '邮箱', dataIndex: 'email' },
    { title: '手机号', dataIndex: 'mobileNo' }
  ];

  async getList() {
    this.loading = true;
    let res: any = await this.roleSrv.getUser({ ...this.filter, page: this.page, limit: this.limit });
    this.dataSource = res.datas;
    this.total = res.totalRecord;
    this.loading = false;
  }

  setFilter(filter: any) {
    this.filter = filter;
    this.getList();
  }

  dataSource: any = [];

  selectedRows: any[] = [];
  onSelectChange(keys: any, rows: any) {
    this.selectedRows = rows;
  }

  created() {}

  async confirm() {
    if (this.selectedRows.length === 0) {
      this.$message.error('请至少选择一个用户');
      return false;
    }
    let { roleKind, roleCode } = this.info;
    if (roleKind == '1') {
      let cd: string[] = roleCode.split('_');
      let type = cd.pop() || '';
      if (type !== '2' && type !== '5') {
        switch (type) {
          case '0':
            type = '1';
            break;
          case '1':
            type = '0';
            break;
          case '3':
            type = '4';
            break;
          case '4':
            type = '3';
            break;
          default:
            break;
        }
        cd.push(type);
        let userList = await this.roleSrv.getRoleUserList({ roleCode_eq: cd.join('_') });
        let exist = false;
        let existName = '';
        userList.forEach((v: any) => {
          if (this.selectedRows.find((s: any) => s.loginName === v.userId)) {
            existName = v.userName;
            exist = true;
          }
        });
        if (exist) {
          this.$message.error(`${existName}不能同时担任同一部门的经办人和复核人角色`);
          return false;
        }
      }
    }
    let params = {
      roleCode: this.roleCode,
      users: this.selectedRows.map((v: any) => ({
        userId: v.loginName,
        userName: v.userName,
        userDept: v.deptName,
        userEmail: v.email,
        staffCode: v.workId,
        domainAccount: v.loginName,
        contractNumber: v.mobileNo
      }))
    };
    let res = await this.roleSrv.addRoleUser(params);
    if (res) {
      this.$emit('success', {});
      this.syncVisible = false;
    }
  }

  bodyStyle = { backgroundColor: '#EEE' };
}
